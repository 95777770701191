import { useContext } from 'react';

import { Box, Button, Typography } from '@mui/material';

import { REDUCERS } from '@/constants/reducers';
import { currentSeason } from '@/constants/site';
import { Context } from '@/context';

import CloseIcon from '../icons/close-icon';
import HistoricIcon from '../icons/historic-icon';
import ProfileIcon from '../icons/profile-icon';
import ProjectionIcon from '../icons/projection-icon';

const ActionsPlayerContent = ({ playerId, style, setOpen, shouldDisplayCloseIcon = true }) => {
    const { dispatch } = useContext(Context);

    const handleClosedModal = () => {
        if (setOpen) {
            setOpen(false);
        }

        dispatch({
            type: REDUCERS.SHOW_MASTER_ACCIONES_PLAYER_ID,
            payload: null,
        });
    };

    const handleClickOnPlayerProfile = () => {
        handleClosedModal();
        dispatch({
            type: REDUCERS.SHOW_PLAYER_PROFILE,
            payload: { playerId, selectedIndex: 2 },
        });
    };

    const handleClickOnPlayerHistoric = () => {
        handleClosedModal();
        dispatch({
            type: REDUCERS.SHOW_FANTASY_HISTORIC,
            payload: { playerId, season: currentSeason },
        });
    };

    const handleClickOnPlayerProjection = () => {
        handleClosedModal();
        dispatch({
            type: REDUCERS.SHOW_MARKET_PROJECTION,
            payload: playerId,
        });
    };

    const itemStyle = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: '#f9fafc',
        borderRadius: '8px',
        padding: '4px 12px',
    };

    return (
        <Box
            sx={{
                border: '1px solid #e0e0e0',
                boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                backgroundColor: '#fff',
                maxWidth: '400px',
                ...style,
            }}
        >
            {/* Encabezado */}
            <Box sx={{ ...itemStyle, borderBottom: '1px solid #e0e0e0' }}>
                <Typography variant="h6" fontWeight="bold" textTransform={'uppercase'}>
                    Acciones sobre el jugador
                </Typography>
                {shouldDisplayCloseIcon && (
                    <CloseIcon onClick={handleClosedModal} style={{ cursor: 'pointer' }} />
                )}
            </Box>

            {/* Cuerpo */}
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '12px',
                    padding: '12px 0',
                }}
            >
                {/* Acción 1 */}
                <Box sx={itemStyle} onClick={handleClickOnPlayerProfile}>
                    <Button variant="outlined" color="primary" fullWidth>
                        <ProfileIcon />
                        Perfil del jugador
                    </Button>
                </Box>

                {/* Acción 2 */}
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        backgroundColor: '#f9fafc',
                        borderRadius: '8px',
                        padding: '4px 12px',
                    }}
                    onClick={handleClickOnPlayerHistoric}
                >
                    <Button variant="outlined" color="secondary" fullWidth>
                        <HistoricIcon />
                        Histórico de mercado
                    </Button>
                </Box>

                {/* Acción 3 */}
                <Box sx={itemStyle} onClick={handleClickOnPlayerProjection}>
                    <Button variant="outlined" color="tertiary" fullWidth>
                        <ProjectionIcon />
                        Proyección de mercado
                    </Button>
                </Box>
            </Box>
        </Box>
    );
};

export default ActionsPlayerContent;
