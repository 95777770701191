import React, { useContext } from 'react';

import ArticleIcon from '@mui/icons-material/Article';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import PollIcon from '@mui/icons-material/Poll';
import PrivacyTipIcon from '@mui/icons-material/PrivacyTip';
import Link from 'next/link';

import { Rutas } from '@/constants/rutas';
import { Context } from '@/context';
import { getTranslatedTeamName, isCountryTeamLeague, obtenerEquipos } from '@/data/equipos';
import { footerLinks, navLinks } from '@/data/nav-items';
import { getTeamImageById } from '@/helpers/images-helper';

import CustomImageComponent from './custom/custom-image';
import ColaboradoresIcon from './icons/colaboradores-icon';
import CreadoresContenidoIcon from './icons/creadores-contenido';
import KitDigitalIcon from './icons/kit-digital-icon';
import PredictingManagerIcon from './icons/predicting-manager-icon';
import SitiosRecomendadosIcon from './icons/sitios-recomendados';
import TwitterIcon from './icons/twitter-icon';
import { getIcon } from './icons-factory';

function FooterSection() {
    const currentYear = new Date().getFullYear();
    const { state } = useContext(Context);
    const { fixtures, preferences } = state;

    const getFooterLinkIcon = (id) => {
        switch (id) {
            case 'encuesta-de-satisfaccion':
                return <PollIcon className="contactanos-icon" />;
            case 'contactanos':
                return <PermContactCalendarIcon className="contactanos-icon" />;
            case 'politica-de-privacidad':
                return <PrivacyTipIcon className="contactanos-icon" />;
            case 'terminos-y-condiciones':
                return <ArticleIcon className="contactanos-icon" />;
            case 'twitter-analiticalfm':
                return <TwitterIcon iconWidth={20} iconHeight={20} style={{ marginRight: '10px' }} />;
            case 'colaboradores':
                return <ColaboradoresIcon iconWidth={27} iconHeight={20} />;
            case 'kit-digital':
                return <KitDigitalIcon iconWidth={28} iconHeight={28} color="#fff" />;
            case 'creadores-contenido':
                return <CreadoresContenidoIcon iconWidth={24} iconHeight={24} />;
            case 'sitios-recomendados':
                return <SitiosRecomendadosIcon iconWidth={24} iconHeight={24} />;
            default:
                return null;
        }
    };

    const getFooterLinksColumn = () => {
        return (
            <div className="footer-text-left-first-row-container">
                {footerLinks.map((link, index) => (
                    <Link key={index} className="go-to-see-player-stats" href={link.path} passHref>
                        {getFooterLinkIcon(link.id)}
                        <span className="li-item ml-5">{link.title}</span>
                    </Link>
                ))}
            </div>
        );
    };

    const getNavItemsColumn = () => {
        return navLinks
            .filter((x) => !x.isAdmin && x.hideInMobile !== true)
            .map((link, index) => (
                <div className="footer-container__navigation-items" key={index}>
                    {link.subMenu ? (
                        <>
                            {link.subMenu
                                ?.filter((x) => x.hideInMobile !== true)
                                ?.map((subMenu) => (
                                    <Link
                                        className="go-to-see-player-stats mb-5"
                                        href={subMenu.path}
                                        passHref
                                        key={subMenu.id}
                                        style={{ display: 'flex' }}
                                    >
                                        {getIcon(subMenu.id, '20px', '20px')}
                                        <span className="li-item ml-5 ">{subMenu.title}</span>
                                    </Link>
                                ))}
                        </>
                    ) : (
                        <Link
                            className="go-to-see-player-stats mb-5"
                            href={link.path}
                            passHref
                            style={{ display: 'flex' }}
                        >
                            {getIcon(link.id, '20px', '20px')}
                            <span className="li-item ml-5">{link.title}</span>
                        </Link>
                    )}
                </div>
            ));
    };

    const getTeamsColumn = () => {
        const equipos = obtenerEquipos(preferences.league);
        const isCountry = isCountryTeamLeague(preferences.league);

        return equipos.map((team, index) => (
            <div className="footer-container__teams-items col-12" style={{ marginBottom: '5px' }} key={index}>
                <Link className="go-to-see-player-stats" href={`${Rutas.EQUIPO}/${team.slug}`} passHref>
                    <CustomImageComponent
                        src={getTeamImageById(team.id, 60)}
                        alt={'equipo'}
                        width={30}
                        height={30}
                        style={{ borderRadius: isCountry ? '50%' : '0' }}
                    />
                    <span className="li-item ml-5">{team.name}</span>
                </Link>
            </div>
        ));
    };

    const getActiveRound = () => {
        if (!fixtures?.rounds) {
            return;
        }

        for (let index = 0; index < fixtures.rounds.length; index++) {
            const round = fixtures.rounds[index];

            if (round.leagueId === preferences.league) {
                for (const fixture of round.fixtures) {
                    if (!fixture?.fixtureLineupsAvailable) {
                        return index;
                    }
                }
            }
        }

        return fixtures?.rounds?.length - 1;
    };

    const getFixturesColumn = () => {
        if (!fixtures?.rounds) {
            return;
        }
        const activeRound = getActiveRound();
        if (activeRound === -1) return null;

        const roundFixtures = fixtures?.rounds?.[activeRound]?.fixtures?.sort(
            (a, b) => new Date(a.date) - new Date(b.date)
        );

        return roundFixtures?.map((fixture, index) => (
            <Link
                key={index}
                className="go-to-see-player-stats"
                href={`${Rutas.PARTIDO}/${fixture.fixtureId}/alineaciones-probables`}
                passHref
            >
                <div
                    className="footer-container__fixtures-items col-12"
                    style={{
                        marginBottom: '0',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '4px',
                    }}
                >
                    <div style={{ display: 'flex', gap: '4px' }}>
                        <CustomImageComponent
                            src={getTeamImageById(fixture.home?.id, 60)}
                            alt={'local'}
                            width={30}
                            height={30}
                        />
                        {getTranslatedTeamName(fixture?.home?.id, fixture?.home?.name)}
                    </div>

                    <div
                        style={{
                            display: 'flex',
                            gap: '4px',
                            borderBottom: '1px solid rgba(255,255,255,.1)',
                            paddingBottom: '8px',
                        }}
                    >
                        <CustomImageComponent
                            src={getTeamImageById(fixture.away?.id, 60)}
                            alt={'visitante'}
                            width={30}
                            height={30}
                        />
                        {getTranslatedTeamName(fixture?.away?.id, fixture?.away?.name)}
                    </div>
                </div>
            </Link>
        ));
    };

    return (
        <footer className="footer-container">
            <div className="container">
                <nav className="row">
                    <div className="col-12 col-lg-3 mb-25">
                        <CustomImageComponent
                            src={`${process.env.NEXT_PUBLIC_IMAGES}/logos/logo.png`}
                            alt="analitica fantasy"
                            width={90}
                            height={90}
                            style={{ marginBottom: '15px' }}
                        />
                        <div className="footer-subtitles" style={{ color: '#fff', lineHeight: '26px' }}>
                            Información
                        </div>
                        <p
                            style={{
                                color: '#fff',
                                lineHeight: '26px',
                                textWrap: 'balance',
                            }}
                        >
                            Analítica Fantasy: Fútbol en clave La Liga Fantasy, Marca, Biwenger, Comunio,
                            Mister y Futmondo. Ofrecemos alineaciones probables, noticias y estadísticas de
                            Fantasy.
                        </p>
                        <div style={{ color: '#fff', lineHeight: '26px', marginBottom: '8px' }}>
                            <span className="footer-subtitles">Conoce Analítica Fantasy</span>
                        </div>
                        <div style={{ marginBottom: '16px' }}>{getFooterLinksColumn()}</div>
                    </div>
                    <div className="col-12 col-lg-3 mb-25">
                        <div style={{ color: '#fff', lineHeight: '26px', marginBottom: '8px' }}>
                            <span className="footer-subtitles">Nuestras páginas</span>
                        </div>
                        {getNavItemsColumn()}
                    </div>
                    <div className="col-12 col-lg-3 mb-25">
                        <div style={{ color: '#fff', lineHeight: '26px', marginBottom: '8px' }}>
                            <span className="footer-subtitles">Posibles onces</span>
                        </div>
                        <div className="row">{getTeamsColumn()}</div>
                    </div>
                    <div className="col-12 col-lg-3 mb-25">
                        <div style={{ color: '#fff', lineHeight: '26px', marginBottom: '8px' }}>
                            <span className="footer-subtitles">Alineaciones probables</span>
                        </div>
                        <div className="row">{getFixturesColumn()}</div>
                    </div>
                    <div className="game-predicting-manager">
                        <Link
                            href={Rutas.PREDICTING_MANAGER}
                            className="footer-subtitles"
                            style={{ marginBottom: '0' }}
                        >
                            <PredictingManagerIcon iconWidth={50} iconHeight={50} size={150} />
                            Juega al Predicting Manager
                        </Link>
                    </div>
                </nav>
            </div>
            <div className="ownership-year">Analítica Fantasy © {currentYear}</div>
        </footer>
    );
}

export default FooterSection;
