import { useEffect, useState } from 'react';

import Link from 'next/link';

import CustomImageComponent from '@/components/shared/custom/custom-image';
import { Rutas } from '@/constants/rutas';
import { getDateMMddHHmm } from '@/helpers/datetime-helper';
import { getPostCardImage } from '@/helpers/images-helper';

import CustomImageNoticiasComponent from '../shared/custom/custom-image-noticias';

export const PostContentCardTwo = ({
    author,
    cover,
    publishedAt,
    title,
    slug,
    height,
    width,
    url,
    isPrimary,
}) => {
    const [localDate, setLocalDate] = useState(null);
    const defaultImage = `${process.env.NEXT_PUBLIC_IMAGES}/logos/logo.png?width=35`;

    useEffect(() => {
        let formattedPublishedAt = getDateMMddHHmm(publishedAt);
        setLocalDate(formattedPublishedAt);
    }, [publishedAt]);

    const getHeight = () => {
        if (width && width > 500) {
            return width / 1.75 + 'px';
        }
        const noticiaHeight = height ? height + 'px' : '230px';
        return noticiaHeight;
    };

    const getAuthorImage = () => {
        if (author?.image) {
            return `${author.image}?width=38`;
        }
        return defaultImage;
    };

    return (
        <div style={{
            height: getHeight(),
        }}
            className="post-content-card-two">
            <Link
                href={url?.length > 1 ? url : `${Rutas.NOTICIAS}/${slug}`}
            >
                {/* Contenedor de la imagen con z-index bajo */}
                <CustomImageNoticiasComponent
                    src={getPostCardImage(cover, width, height)}
                    alt="Imagen de la noticia"
                    priority={isPrimary}
                />

                {/* Contenedor de la información (título, autor, fecha) con z-index alto */}
                <div
                    style={{
                        position: 'absolute',
                        bottom: '-60px',
                        left: 0,
                        color: '#212121',
                        padding: '10px',
                        background: '#fff',
                        marginLeft: '12px',
                        marginRight: '12px',
                        height: '100px',
                        zIndex: 2, // Información con z-index alto
                        width: 'calc(100% - 24px)', // Para mantener el padding horizontal
                        border: '1px solid rgba(0, 0, 0, .1)',
                        borderRadius: '2px',
                    }}
                >
                    <h2
                        style={{
                            fontSize: '18px',
                            lineHeight: '1.35',
                            fontWeight: 600,
                            margin: 0, // Asegurarse de que no haya margen adicional
                            display: '-webkit-box',
                            WebkitBoxOrient: 'vertical',
                            WebkitLineClamp: 2, // Limitar a 2 líneas
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                        }}
                    >
                        {title}
                    </h2>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            marginTop: '8px',
                        }}
                    >
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <CustomImageComponent
                                width={25}
                                height={25}
                                src={getAuthorImage()}
                                alt="Autor"
                                style={{ marginRight: '8px', borderRadius: '50%' }}
                            />
                            <span style={{ fontSize: '12px', color: '#000', textTransform: 'uppercase' }}>
                                {author?.name}
                            </span>
                        </div>
                        <span style={{ fontSize: '12px', color: '#000' }}>{localDate}</span>
                    </div>
                </div>
            </Link>
        </div>
    );
};
