import { Fragment } from 'react';

import { Typography } from '@mui/material';
import Link from 'next/link';

import { LEAGUES } from '@/constants/leagues';
import { Rutas } from '@/constants/rutas';
import { obtenerEquipos } from '@/data/equipos';
import { getTeamImageById } from '@/helpers/images-helper';

import CustomImageComponent from './custom/custom-image';

const TeamLogoComponent = () => {
    return (
        <Fragment>
            <div className="team-logo-container">
                <nav className="container">
                    <ul className="team-logo-container__subcontainer" style={{ listStyle: 'none' }}>
                        {obtenerEquipos(LEAGUES.LA_LIGA).map((team, index) => (
                            <li key={index} style={{ position: 'relative' }}>
                                <Link href={`${Rutas.EQUIPO}/${team.slug}`}>
                                    <CustomImageComponent
                                        width={40}
                                        height={40}
                                        className="team-logo-container__image"
                                        src={getTeamImageById(team.id, 60, 60)}
                                        alt={`logo-${team.name}`}
                                        loading="eager"
                                    />
                                    {team.group && (
                                        <Typography
                                            style={{
                                                position: 'absolute',
                                                right: '-8px',
                                                bottom: '-4px',
                                                color: '#fff',
                                                fontSize: '10px',
                                                opacity: 0.55,
                                            }}
                                        >
                                            {team.group}
                                        </Typography>
                                    )}
                                </Link>
                            </li>
                        ))}
                    </ul>
                </nav>
            </div>
        </Fragment>
    );
};

export default TeamLogoComponent;
