import { REDUCERS } from '@/constants/reducers';

export function playerProfile(state, action) {
    switch (action.type) {
        case REDUCERS.SHOW_PLAYER_PROFILE:
            return { ...state, playerProfile: action.payload };
        case REDUCERS.HIDE_PLAYER_PROFILE:
            return { ...state, playerProfile: { playerId: null, fantasyId: null } };
        case REDUCERS.SET_ALL_PLAYERS:
            return { ...state, playerProfile: { ...state.playerProfile, allPlayers: action.payload } };
        case REDUCERS.SHOW_MARKET_PROJECTION:
            return {
                ...state,
                marketProjection: action.payload,
            };
        case REDUCERS.SHOW_MASTER_ACCIONES_PLAYER_ID:
            return {
                ...state,
                masterAccionesPlayerId: action.payload,
            };
        default:
            return state;
    }
}
